.aboutHome-container {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     gap: 3px;
     width: 100%;
}

.tituloAboutContainer {
     display: flex;
     align-items: center;
     justify-content: space-evenly;
     width: 100vw;
     margin-bottom: 50px;
     font-weight: 600;
}

.lineaAbout {
     width: 25%;
     height: 2px;
     background: linear-gradient(45deg, #3F0E54, lighten($color: #3F0E54, $amount: 25));
}

.contenidoAbout {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
}

.textoAbout {
     background: linear-gradient(45deg, #3F0E54, lighten($color: #3F0E54, $amount: 25));
     border-radius: 10px;
     box-shadow: 33px 47px 44px -45px rgba(63, 14, 84, 1);
     height: 50vh;
     width: 50%;
     margin-top: 50px;
     color: #fff;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: flex-start;
     padding: 5%;
     font-family: 'Nunito', sans-serif;

     h3 {
          font-weight: 600;
          margin-bottom: 25px;
     }
}

.imageAboutContainer {
     width: 50%;
     height: 50vh;
     margin-top: 50px;
     display: flex;
     flex-direction: column;
}

.imageAbout {
     object-fit: contain;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
}

.imagen {
     width: 100%;
     pointer-events: none;
     overflow: hidden;
     object-fit: cover
}

.botonAboutHome {
     width: 75%;
     margin: auto;
     background-color: #D0C20A;
     margin-bottom: 15px;
     padding: 10px;
     border-radius: 20px 0 20px 0;
     border-bottom: 4px solid transparent;
     transition: border .3s ease;
     font-family: 'Nunito', sans-serif;
     font-weight: 600;
     color: #fff;

     &:hover {
          border-bottom: 4px solid #3F0E54;
          color: #3F0E54
     }
}



@media (max-width: 1000px) {
     .imageAboutContainer {
          height: 100%;
          width: 100%;

     }

     .imagen {
          object-fit: cover;
     }

     .textoAbout {
          width: 100%;
     }
}