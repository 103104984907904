.tajetaObjetivo {
     color: #fff;


     .card-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
     }

     h5 {
          font-size: 16px;
          font-weight: 600;
          text-align: center;
     }

     span {
          font-size: 75px;
     }
}

.tituloObjetivos {
     font-size: 5vh;
     text-align: center;
     font-weight: 900;

     span {
          font-size: 6vh;
          color: #33C1E8;
     }

}

.subTituloObjetivos {
     text-align: center;
     margin-bottom: 100px;
}

.ObjetivosHome {
     gap: 15px;
     margin: 15px;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;

}

.opacity {
     animation-name: opacar;
     animation-duration: 2s;
     animation-delay: .2s;
     animation-fill-mode: forwards;
     animation-timing-function: ease-in;

}


@keyframes opacar {
     0% {
          opacity: 1;


     }

     100% {
          opacity: .2;


     }

}