header {
     display: flex;
     justify-content: center;

}

.aboutHome-container {
     gap: 5px
}

.contacto-rapido {
     height: 5vh;
     background-color: #3F0E54;
     display: flex;
     justify-content: space-evenly;
     color: #D0C20A;


     a {
          text-decoration: none;
          color: #D0C20A;
     }

     .donar {
          background-color: #D0C20A;
          color: #3F0E54;
          margin: 5px;
          padding: 5px 10px;
          border-radius: 10px;
          border-bottom: 2px solid transparent;
          transition: all .3s ease-in-out;

          &:hover {
               border-bottom: 2px solid #fff;
               background-color: darken($color: #D0C20A, $amount: 4);
               color: #fff;
          }
     }
}




.divLogo {
     display: flex;
     justify-content: flex-start;
     align-items: flex-start;
     margin-left: 4vh;
}

.logoNav {
     width: 50px;
     margin: 1vh;
}

a {
     text-decoration: none;
     display: flex;
     justify-content: flex-start;
     align-items: center;

}



.marcaNav {
     font-size: 3vh;
     font-family: 'Nunito', sans-serif;
     color: #3F0E54;

}

.iconoMenu {
     margin-right: 4vh;
     z-index: 500;

     span {
          font-size: 40px;
          cursor: pointer;
     }
}

.menuOffCanvas {
     position: absolute;
     top: 0;
     left: 0;
     z-index: 100;
     width: 0;
     height: 100vh;
     background: linear-gradient(45deg, #3F0E54, lighten($color: #3F0E54, $amount: 25));
     display: none;



     ul {
          list-style: none;
          font-family: 'Nunito', sans-serif;
          font-size: 3vh;
          gap: 2vh;

          li {
               padding: 10px 15px;
               text-align: center;
               border-bottom: 1px solid transparent;

               &:hover {
                    border-bottom: 1px solid #D0C20A;
                    cursor: pointer;
                    text-shadow: 0px 0px 2px #D0C20A;
               }

               .linkMenu {
                    color: #fff;
               }
          }
     }

}

.cerrarMenu {
     color: #fff;
}

.activo {
     display: flex;
     justify-content: center;
     height: 100vh;
     align-items: center;
     color: #fff;
     position: fixed;
     animation-name: aparecemenu;
     animation-fill-mode: forwards;
     animation-duration: .4s;
     animation-timing-function: ease-in;


}

.pegajoso {
     background-color: #fff;
     opacity: .9;
     backdrop-filter: blur(5px);
     z-index: 50;
}


@keyframes aparecemenu {
     0% {
          width: 0;
          opacity: 0;
     }

     100% {
          width: 100%;
          opacity: 1;
     }

}