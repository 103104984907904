.imagenSlide {
     height: 75vh;
     object-fit: cover;
     object-position: center;
}

.infoBanner {
     background-color: rgba(208, 194, 10, .8);
     display: flex;
     justify-content: center;
     align-items: center;
     height:180px;

}

.textoImagen {
     font-family: 'Nunito', sans-serif;
     font-weight: 500;
     font-size: 16px;
     line-height: 18px;
     margin-top: 30px;
     width: 80%;
     margin: 5px auto;
     padding: 5px;
}

.botonImagen {
     border: none;
     border-bottom: 2px solid transparent;
     border-radius: 20px 0 20px 0;
     padding: 8px 16px;
     margin-bottom: 30px;
     margin-top: 5px;
     background-color: #3F0E54;
     color: #fff;
     transition: all .2s ease-in;

     &:hover {
          border-bottom: 2px solid #fff;
          border-radius: 0 20px 0 20px;
          background-color: lighten($color: #3f0e54, $amount: 10);
     }
}

.tituloImagen {
     font-family: 'Nunito', sans-serif;
     font-weight: 800;
     margin-top: 20px;
     margin-bottom: 20px;
     letter-spacing: 1px;
     font-size: 20px;
}

@media (max-width: 770px) {
     .imagenSlide {
          height: 60vh;
          object-fit: cover;
          object-position: center;
     }

     .infoBanner {
          display: flex;
          height: 80%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: rgba(208, 194, 10, .6);

          .textoImagen {
               height: 60%;
               font-size: 16px;
               width: 100%;
          }

     }

     .botonImagen {
          margin-top: 5px
     }

}