.footer{
    width: 100%;
    height: 60px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

svg{
    cursor: pointer;
}